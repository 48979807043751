@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&display=swap');

html, body {
  margin: 0px;
  padding: 0px;
}
body {
  font-family: 'Caveat', cursive;
  font-size: 20px;
  color: #000000;
  box-sizing: content-box;
}

#rpg-note {
  display: flex;
  height: 100vh;
}

h1 {
  font-size: 32px;
  font-weight: bold;
}
h2 {
  font-size: 27px;
  margin-top: 16px;
  margin-bottom: 0px;
  font-weight: bold;
}
h3 {
  font-size: 21;
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: bold;
}

p {
  margin: 4px;
}

a {
  text-decoration: none;
}

input {
  display: block;
  width: 100%;
  font-family: 'Caveat', cursive;
  font-size: 20px;
  text-align: center;
  border: none;
  background-color: transparent;
  border-radius: 4px;
}
input:focus{
  outline: none;
}

/* react-quill */
#rpg-note .quill {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
#rpg-note .no-toolbar .ql-toolbar {
  display: none;
}
#rpg-note .ql-toolbar {
  border: none;
  font-family: 'Caveat', cursive;
  padding: 4px;
}
#rpg-note .ql-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: auto;
  border: none;
  font-family: 'Caveat', cursive;
  font-size: 16px;
  border-radius: 16px 16px 0px 0px;
  box-shadow: inset 0px 2px 8px rgba(0,0,0,0.1);
}
#rpg-note .ql-editor {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

/* react-split */
.gutter {
  flex-shrink: 0;
}
.gutter-horizontal {
  cursor: ew-resize;
}
.gutter-vertical {
  cursor: ns-resize;
}

.gutter-horizontal::after, .gutter-vertical::after {
  font-family: monospace;
  color: rgba(0,0,0,0.2);
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gutter-horizontal::after {
  content: "║";
}
.gutter-vertical::after {
  content: "══";
}